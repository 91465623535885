.menu {
  ul {
    background-color: #343A41 !important;
    li {
      height: 72px !important;
      border-radius: 0 !important;
      width: 100% !important;
      margin-left: 0 !important;
      display: flex !important;
      align-items: center;

      svg {
        min-width: 24px !important;
        height: 24px !important;
      }
    }
    .ant-menu-item-selected {
      background: #282C31;
    }
  }
}

.ant-btn-variant-solid {
  border-radius: 4px;
  background: #E43D00 !important;
}

.ant-btn-variant-solid:disabled {
  color: #e4e5e8;
  background: #E43D00 !important;
  opacity: 0.5;
}

.ant-layout {
  background: white !important;
}

.pdf-viewer {
  max-width: min-content;
  margin: auto;
  max-height: calc(100vh - 465px);
  overflow-y: scroll;
  border: 1px solid #e4e5e8;
}

.pdf-options-name {
  font-size: 16px;
  font-weight: 700;
  line-height: 24px;
  text-align: left;
  color: black;
  margin: 20px;

}

.pages-container {
  width: 100%;
  border-top: 1px solid #e4e5e8;
  margin: auto;
  padding-top: 10px;
}

.spinner-container {
  margin: auto;
  width: 50px;
}

.page-viewer {
  margin-bottom: 10px;
  border-bottom: 7px solid #e4e5e8;
  display: flex;
  justify-content: center;
}
